import { Filter } from './filters'

export enum ExportStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export enum MissionElement {
  HOME = 'home',
  SECTION = 'section',
  VARIANT = 'variant',
  STEP = 'step',
  ENGINE = 'engine',
}

export enum LocomotivePosition {
  EN_TETE = 'En tête',
  EN_QUEUE = 'En queue',
}

export interface OnOpenDelete {
  onOpenDelete: (
    message: string,
    confirmDelete: (() => Promise<void | {error: unknown}>),
  ) => void,
}

export interface GenericSimpleObject {
  id: string
  libelle: string
}

export interface Egs {
  id: string
  idClient: number
  libelle: string
  lrEgs: string
}

export interface Bfr {
  id: string
  idClient: number
  numero: number
  libelle: string
  egss: Egs[]
}

export interface Matrix {
  id: string,
  missionId: string,
  statutExport: ExportStatus,
  rangTroncon: number,
  nombreTroncons: number,
  origineTroncon: string,
  destinationTroncon: string,
  matrice: Matrice[]
}

export interface Mission {
  id: string
  missionId: string
  egs: Egs | null
  serviceAnnuel: string
  debutValidite: string
  finValidite: string
  missionDeSecurite: boolean
  vitesseMission: number
  regimeFreinage: string
  estHorsBfr: boolean
  codeBfr: Bfr | null
  statutExport: ExportStatus
  errorMessage: {
    message: string
    httpCode: number
  }[]
}

export interface Section {
  id: string
  destinationTroncon: string
  missionId: string
  nombreTroncons: number
  origineTroncon: string
  rangTroncon: number
  matrice: Matrice[]
}

export interface Matrice {
  capaciteLongueur: number
  capacitePoids: number
  cumulLongueur: number
  cumulPoids: number
  horaireArrivee: string
  horaireDepart: string
  jour: number
  lots: Lot[]
  remplissage: number
  residuelLongueur: number
  residuelPoids: number
  alerteCapacite: boolean
  numeroSillon: string[]
}

export interface Lot {
  id: string
  poids: number
  longueur: number
  rang: number
  nombreWagons: number
  libelle: string
}

export interface Variant {
  estParDefaut: boolean
  id: string
  jourHebdo: string[]
}

export interface VariantAvailability {
  id: string
  jourHebdoPris: string[]
}

export interface Place {
  id: string
  codePays: number
  codeLieu: number
  codeGare: number
  codePoint: string
  ngramme: string
}

export interface StopStage {
  id: string
  heureArrivee: string
  decalageDepart: number
  heureDepart: string
  decalageArrivee: number
  lieu: Place
}

export interface SectionDetail {
  id: string
  arretArrivee: StopStage
  arretDepart: StopStage
  tube: Filter
  rang: number
  remplissage: number
  capacitePoids: number
  capaciteLongueur: number
  sensReleve: string
  joursArrivee: string[]
  joursDepart: string[]
  numerosSillon: string[]
}

export interface Serie {
  id: string
  idClient: number
  libelle: string
  typeTraction: string
  typeAptitudeUm: string
}

export interface Entity {
  id: string
  idClient: number
  code: string
  libelle: string
  filiale: string
}

export interface LocomotiveType {
  id: string,
  serie: Serie,
  ccoAssistance: GenericSimpleObject
  entiteAssistance: Entity,
  position: string
}

export interface TypeAgent {
  id: string
  idClient: number
  code: string
  libelle: string
  codeTypeStatut: string
}

export interface Agent {
  id: string
  typeAgent: TypeAgent
}

export interface Step {
  id: string,
  arretArrivee: StopStage,
  arretDepart: StopStage,
  rang: number,
  codeReseau: string,
  locomotives: LocomotiveType[],
  agents: Agent[],
  cco: GenericSimpleObject
  gesco: GenericSimpleObject
  indiceComposition: GenericSimpleObject
  estHautLePied: true,
  distance: number
  sensDebranchement: string
  joursArrivee: string[]
  joursDepart: string[]
  numeroSillon: string
}
